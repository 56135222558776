<template>
  <div class="train">
    <Fullstack></Fullstack>
  </div>
</template>

<script>
// @ is an alias to /src
import Fullstack from '../components/Fullstack';

export default {
  name: 'FullTraining',
  components: {
    Fullstack
  }
}
</script>
