<template>
  <div class="train">
    <DataScience></DataScience>
  </div>
</template>

<script>
// @ is an alias to /src
import DataScience from '../components/DataScience';

export default {
  name: 'DsTraining',
  components: {
    DataScience
  }
}
</script>
