<template>
  <div class="hero mt-4">
    <div class="container-fluid">
        <div class="ser-our mb-4">
            <h3><span class="ser-our_first">python</span></h3>
        </div>
        <div class="container">
            <div class="ouroffer">
                    <div class="container">
                        <div class="card text-center">
                            <div class="card-header">
                                <ul class="nav nav-pills card-header-tabs">
                                <li class="nav-item ">
                                    <a class="nav-link clickable"  @click="toggle='basic'" :class="toggle=='basic' ? 'active' :''">Basic</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link clickable" @click="toggle='advance'" :class="toggle=='advance' ? 'active' :''">Advance</a>
                                </li>
                                </ul>
                            </div>

                            <div class="card-body">
<!-- FOR ADVANCE CONTENT -->
                                <div v-if="toggle=='advance'">
                                    <h5 class="card-title text-center fs-3">Your Expectations</h5>
                                    <div class="card-text">
                                        <ul class="class-ul" v-for="topic in advances" :key="topic">
                                            <li class="claz-li">
                                                <span class="claz-li-2">{{topic}}.</span>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
<!-- FOR BASIC CONTENT -->
                                <div v-else>
                                    <h5 class="card-title text-center fs-3">Your Expectations</h5>
                                    <div class="card-text active">
                                        <ul class="class-ul" v-for="topic in basicc" :key="topic">
                                            <li class="claz-li">
                                                <!-- <i class="fa fa-soccer-ball-o text-primary  claz-li-1" aria-hidden="true"></i>  -->
                                                <span class="claz-li-2">{{topic}}.</span>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button class="btn btn-primary bt-style" @click="loadModal">Register Now</button>
                            </div>

                            <!-- Modal -->
                 <div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                            <div class="modal-header">
                                                    <h5 class="modal-title h2">REGISTER HERE</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                </div>
                                        <div class="modal-body">
                                            <div class="container-fluid fs-2">
                                                <form method="POST" data-netlify="true"  data-netlify-honeypot="bot-field" name="Python">
                                                    <input type="hidden" name="form-name" value="Python">
                                                    <div class="d-none"><label>For Spam-bot, fill not: <input name="bot-field"></label></div>
                                                    <div class="form-group w-auto"><input type="text" class="form-control fs-2" name="name" placeholder="How Can We Address You"></div>
                                                    <div class="form-group w-auto"><input type="email" class="form-control fs-2" name="email" id="" placeholder="Your Email"></div>
                                                    <div class="form-group w-auto"><input type="tel" class="form-control fs-2" name="phone" id="" placeholder="(format): 08112121212"  pattern="[0-9]{11}" required></div>
                                                    <div class="form-group w-auto"><textarea name="message" class="form-control fs-2" cols="28" rows="4" placeholder="Kindly Reach Out To Us"></textarea></div>
                                                    <div class="form-group"><input type="submit" value="Reach Out" class="btn btn-primary bt-style"></div>
                                                    <!-- <button class="btn btn-primary bt-style">Reach Out</button -->
                                                </form>
                                            </div>
                                        </div>
                                        <!-- <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary">Save</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
             </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Python',
  data(){
      return{
          toggle:'basic',
          basicc:[
              'Course Introduction','Why Python','Stepping into the world of Python',
              'Program flow control in prthon','lists, ranges & Tupples in Python','Binary Number system',
              'Python Dictionaries and sets','Input and Output (I/O) in Python'
          ],

          advances:[
              'Modules and Function in Pyhton','object Oriented Python','using databases in Pyhton',
              'Generators, Conprehensions and Lamda Expressions','Packages','Course Remaster in Progross',
          ]
      }
  },
   methods:{
      loadModal(){
          $('#modelId').modal('show');
      }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
