<template>
  <div class="hero mt-4">
    <div class="container-fluid">
        <div class="ser-our mb-4">
            <h3><span class="ser-our_first">data science master</span></h3>
        </div>
        <div class="container">
            <div class="ouroffer">
                    <div class="container">
                        <div class="card text-center">
                            <div class="card-header">
                                <ul class="nav nav-pills card-header-tabs">
                                <li class="nav-item ">
                                    <a class="nav-link clickable"  @click="toggle='basic'" :class="toggle=='basic' ? 'active' :''">Basic</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link clickable" @click="toggle='excel'"  :class="toggle=='excel' ? 'active' :''">Excel</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link clickable" @click="toggle='sql'" :class="toggle=='sql' ? 'active' :''">SQL</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link clickable" @click="toggle='python'" :class="toggle=='python' ? 'active' :''">Python</a>
                                </li>
                                </ul>
                            </div>

                            <!-- FOR EXCEL CONTENT -->
                            <div class="card-body">
                                <div v-if="toggle=='excel'">
                                    <h5 class="card-title text-center fs-3">Your Expectations</h5>
                                    <div class="card-text">
                                        <ul class="card-ul" v-for="topic in excell" :key="topic">
                                            <li class="claz-li"> 
                                                <span class="claz-li-2">{{topic}}.</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
<!-- FOR SQL CONTENT -->
                                <div v-else-if="toggle=='sql'">
                                    <h5 class="card-title text-center fs-3">Your Expectations</h5>
                                    <div class="card-text">
                                        <ul class="card-ul">
                                            <li class="claz-li" v-for="topic in sqll" :key="topic">
                                               <span class="claz-li-2"> {{topic}}.</span>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
<!-- FOR PYTHON CONTENT -->
                                <div v-else-if="toggle=='python'">
                                    <h5 class="card-title text-center fs-3">Your Expectations</h5>
                                    <div class="card-text">
                                        <ul class="card-ul" v-for="topic in pythonn" :key="topic">
                                            <li class="claz-li">
                                                <span class="claz-li-2"> {{topic}}.</span>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
<!-- FOR BASIC CONTENT -->
                                <div v-else>
                                    <h5 class="card-title text-center fs-3">Your Expectations</h5>
                                    <div class="card-text active">
                                        <ul class="card-ul" v-for="topic in basicc" :key="topic">
                                            <li class="claz-li"> 
                                               <span class="claz-li-2"> {{topic}}.</span>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button class="btn btn-primary bt-style" @click="loadModal">Register Now</button>
                            </div>

                            <!-- Modal -->
                            <div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                            <div class="modal-header">
                                                    <h5 class="modal-title h2">REGISTER HERE</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                </div>
                                        <div class="modal-body">
                                            <div class="container-fluid fs-2">
                                                <form method="POST" data-netlify="true" data-netlify-honeypot="bot-field" name="Data Science">
                                                    <input type="hidden" name="form-name" value="Data Science">
                                                    <div class="d-none"><label>For Spam-bot, fill not: <input name="bot-field"></label></div>
                                                    <div class="form-group w-auto"><input type="text" class="form-control fs-2" name="name" placeholder="How Can We Address You"></div>
                                                    <div class="form-group w-auto"><input type="email" class="form-control fs-2" name="email" id="" placeholder="Your Email"></div>
                                                    <div class="form-group w-auto"><input type="tel" class="form-control fs-2" name="phone" id="" placeholder="(format): 08112121212"  pattern="[0-9]{11}" required></div>
                                                    <div class="form-group w-auto"><textarea name="message" class="form-control fs-2" id="" cols="28" rows="4" placeholder="Kindly Reach Out To Us"></textarea></div>
                                                    <div class="form-group"><input type="submit" value="Reach Out" class="btn btn-primary bt-style"></div>
                                                </form>
                                            </div>
                                        </div>
                                        <!-- <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary">Save</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
             </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataScience',
  methods:{
      loadModal(){
          $('#modelId').modal('show');
      }
  },
  data(){
      return{
          toggle:'basic',
          basicc:[
              'Introduction to Reseach Methods','Visualising Data','Google Spreadsheet',
              'Central Tendency','Variaility','Standardization','Normal Distribution','Sampling Distribution'
          ],

          sqll:[
              'Basic SQL and filtering with SQL','Joining Tables in SQL','SQL Aggregation: SUM, AVG COUNT',
              'SQL Aggregation: CASE, HAVING, DATE','Nexted Queries (Subqueries) & Tempporary tables in SQL',
              'Data Cleaning using SQL'
          ],

          excell:[
            'Excel Foundations','Basic Formular and Function','Statictics in Excel','Handling Data and Pivot Tables',
            'Visualization using Charts in Excel', 'Data analysis/Business Intelligence using Case Study',
            'Introduction to Tableau','Creating Visualization with Tableau','Tell Stories with Tableau'
          ],

          pythonn:[
              'Data types and Operators','Structures and Type Conversion','Built in function and Style Guildline',
              'Control Flow: Using Loops.','list Comprehension.','Creating function in Python.',
              'Python Libraries: Numpsy, Pandas and Matplotlib.','Data Analysis and Cleaning Using Pandas',
          ]
      }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
