<template>
  <div class="hero mt-4">
    <div class="container-fluid">
        <div class="ser-our mb-4">
            <h3><span class="ser-our_first">fullstack web development</span></h3>
        </div>
        <div class="container">
            <div>
                    <div class="container">
                        <div class="card text-center">
                            <div class="card-header">
                                <ul class="nav nav-pills card-header-tabs">
                                <li class="nav-item">
                                    <a class="nav-link clickable" @click="toggle='html'" :class="toggle=='html' ? 'active' :''" >HTML</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link clickable" @click="toggle='css'" :class="toggle=='css' ? 'active' :''">CSS</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link clickable" @click="toggle='javascript'" :class="toggle=='javascript' ? 'active' :''" >JavaScript</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link clickable" @click="toggle='database'" :class="toggle=='database' ? 'active' :''">Databases and Back-End</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link clickable" @click="toggle='git'" :class="toggle=='git' ? 'active' : ''" >Git Flow</a>
                                </li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <div v-if="toggle=='css'">
                                    <h5 class="card-title text-center fs-3">Your Expectations</h5>
                                    <div class="card-text">
                                        <ul class="card-ul">
                                            <li class="claz-li" v-for="css in cssS" :key="css.id">
                                              <span class="claz-li-2">  {{css}}.</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
<!-- FOR HTML JAVASCRIPT -->
                                <div v-else-if="toggle=='javascript'">
                                    <h5 class="card-title text-center fs-3">Your Expectations</h5>
                                    <div class="card-text">
                                        <ul class="card-ul">
                                            <li class="claz-li" v-for="java in javaS" :key="java.id">
                                               <span class="claz-li-2"> {{java}}.</span>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
<!-- FOR DATABASE CONTENT -->
                                <div v-else-if="toggle=='database'">
                                    <h5 class="card-title text-center fs-3">Your Expectations</h5>
                                    <div class="card-text">
                                        <ul class="card-ul">
                                            <li class="claz-li" v-for="mysql in mysqls" :key="mysql.id">
                                                <span class="claz-li-2">{{mysql}}.</span>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
<!-- FOR DATABASE CONTENT -->
                                <div v-else-if="toggle=='git'">
                                    <h5 class="card-title text-center fs-3">Your Expectations</h5>
                                    <div class="card-text">
                                        <ul class="card-ul">
                                            <li class="claz-li" v-for="git in gits" :key="git.id">
                                                <span class="claz-li-2">{{git}}.</span>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
<!-- FOR HTML CONTENT -->
                                <div v-else>
                                    <h5 class="card-title text-center fs-3">Your Expectations</h5>
                                    <div class="card-text active">
                                        <ul class="card-ul">
                                            <li class="claz-li" v-for="html in htmls" :key="html.id">
                                                <span class="claz-li-2">{{html}}.</span>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button class="btn btn-primary bt-style" @click="loadModal">Register Now</button>
                            </div>
                            
                            <!-- Modal -->
                            <div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                            <div class="modal-header">
                                                    <h5 class="modal-title h2">REGISTER HERE</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                </div>
                                        <div class="modal-body">
                                            <div class="container-fluid fs-2">
                                                <form method="POST" data-netlify="true"  data-netlify-honeypot="bot-field" name="Fullstack">
                                                    <input type="hidden" name="form-name" value="Fullstack">
                                                    <div class="d-none"><label>For Spam-bot, fill not: <input name="bot-field"></label></div>
                                                    <div class="form-group w-auto"><input type="text" class="form-control fs-2" name="name" placeholder="How Can We Address You" required></div>
                                                    <div class="form-group w-auto"><input type="email" class="form-control fs-2" name="email" id="" placeholder="Your Email" required></div>
                                                    <div class="form-group w-auto"><input type="tel" class="form-control fs-2" name="phone" id="" placeholder="(format): 08112121212"  pattern="[0-9]{11}" required></div>
                                                    <div class="form-group w-auto"><textarea name="message" class="form-control fs-2" id="" cols="28" rows="4" placeholder="Kindly Reach Out To Us"></textarea></div>
                                                    <div class="form-group"><input type="submit" value="Reach Out" class="btn btn-primary bt-style"></div>
                                                </form>
                                            </div>
                                        </div>
                                        <!-- <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary">Save</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
             </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Fullstack',
  data(){
      return{
          toggle:'html',
          clas:null,
          htmls:['Introduction to HTML','Paragraphs, Headings, and Text','HTML Links','HTML Images','HTML Lists',
          'HTML Tables',' Sectioning a Web Page','Audio and Video'],

          mysqls:['MYSQL (Relational)','MONGO DB (Non-Relational)','PHP','Node js'],

          javaS:['JavaScript Basics','Variables, Arrays, and Operators','JavaScript Functions','Built-In JavaScript Objects',
          'Conditionals and Loops','Event Handlers and Listener',
          `Listen for and handle events,such as mouse clicks and page loads, with JavaScript.`,
          'The HTML Document Object Model',' CSS Object Model.','How to handle and prevent JavaScript errors.',`
          Errors and Exceptions`],
         
          cssS:['Css syntax',' Use div and span tags appropriately','All the common CSS properties and their values.',
                  ' To use CSS resets and normalizers.','Best practices for choosing units of measurement.','How to select CSS fonts and to download and use new fonts.',
                  'To work with borders, margin, and padding (the box model).','To style tables with CSS.','To use positioning.',
                  ' To use transforms and transitions rotate, scale, and skew elements and create animations.',' About flex, grid, and multi-column layouts.',
                  'To style lists and create drop-down and fly-out navigation menus.','To use media queries to style pages for different device sizes and media.'
          ],
          gits:['Working with remote repository','list all files in your repository','Initiating local directory as git repository',
          'Adding your files to git repository','Checking the status of your git repository', 'How to commit','How to add remote repository to your working directory',
          'How to push to your remote repository','Working with different branches'],
      }
  },
  methods:{
      loadModal(){
          $('#modelId').modal('show');//Load the modal
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
     
</style>
