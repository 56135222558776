<template>
  <div class="train">
    <Python/>
  </div>
</template>

<script>
// @ is an alias to /src
import Python from '../components/Python';

export default {
  name: 'PyTraining',
  components: {
    Python
  }
}
</script>
